.App {
  text-align: center;
}

h2 {
  color: #222222;
}

html,
body {
  overflow-x: hidden;
}

.border {
  border-color: #222222 !important;
}

.text-gray { color: #222222; }

.bg-light {
  background-color: #D3D3D3 !important;
}

.text-lightgray {
  color: #d3d3d3;
}

.footer {
  background-color: #222222;
  border-top: 1px solid lightgray;
  color: white;
  min-height: 200px;
  padding-bottom: 20px;
}

.cluck {
  background-color: red;
  border-radius: 10px;
  box-shadow: 1px 1px 4px gray;
  color: white;
  right: -26px;
  height: 20px;
  position: absolute;
  top: -8px;
  width: 20px;
}

.dark {
  background-color: #121212 !important;
  color: #fff;
}

.dark td,
.dark table {
  color: white !important;
}

.dark .dropdown-menu-dark {
  background-color: black !important;
  border: 1px solid gray
}

.dark .listing-image {
  border: 1px solid #59CBE8 !important;
  background-color: transparent !important;
}

.dark .blue-text {
  color: #59CBE8;
}

.header { border-bottom: 1px solid lightgray;}
.dark .header {
  background-color: #121212;
  border-bottom: 1px solid #333333;
}

.dark .footer {
  background-color: #121212;
  border-top: 1px solid #333333;
}

.dark .border-top {
  border-color: darkgray !important;
}

.dark .hucklberry-home-photo,
.dark .hucklberry-home-photo-half {
  background-color: #383838;
}

.dark p, .dark h3, .dark h2 {
  color: #d3d3d3 !important;
}

#box {
  display: flex;
  align-items: center;
  justify-content: center;
  width: 400px;
  height: 200px;
  color: white;
  font-family: 'Raleway';
  font-size: 2.5rem;
}
.gradient-border {
  --borderWidth: px;
  background: white;
  position: relative;
  border-radius: var(--borderWidth);
}
.gradient-border:after {
  content: '';
  position: absolute;
  top: calc(-1 * var(--borderWidth));
  left: calc(-1 * var(--borderWidth));
  height:10;
  width: 10;
  background: linear-gradient(60deg, #f79533, #f37055, #ef4e7b, #a166ab, #5073b8, #1098ad, #07b39b, #6fba82);
  border-radius: calc(2 * var(--borderWidth));
  z-index: -1;
  animation: animatedgradient 3s ease alternate infinite;
  background-size: 300% 300%;
}


@keyframes animatedgradient {
	0% {
		background-position: 0% 50%;
	}
	50% {
		background-position: 100% 50%;
	}
	100% {
		background-position: 0% 50%;
	}
}

.dark .reservation-card {
  background-color: #121212;
  box-shadow: unset;
  border-radius: 8px;
  border: 1px solid gray
}

.dark .characters {
  background-color: black !important;
}
.dark .characters img {
  border: 1px solid #fff;
}

.dark .offcanvas-lg {
  background-color: #222222;
}

.dark .no-guests { 
  background-color: black;
  border: 1px solid #fff;
}
.dark .tooltip { 
  border: 1px solid #fff;
  background-color: black;
  border-radius: 8px;
}

.reservation-card {
  background-color: rgb(247, 247, 247);
  box-shadow: 2px 2px 6px lightgray;
  border-radius: 8px;
  border-left: 1px solid lightgray;
}

.tooltip { 
  border: 1px solid #fff;
  background-color: black;
  border-radius: 8px;
}

.dark .btn-close {
  background-color: white !important;
}
.dark .show-map {
  box-shadow: unset;
  border: 1px solid #59CBE8;
}

.dark .list-group-item {
  background-color: black;
}

.dark .card {
  background-color: black;
  border: 1px solid #fff;
}

.dark .form-control {
  background-color: black;
  color: #fff;
}

.mt-xs {
  margin-top: 1px;
}

.danger {
  color: red;
}

.vh-75 {
  height: 75vh !important;
}

.form-control:focus {
  border-color: white !important;
}

.fs-7 {
  font-size: 14px !important;
}

.fs-8 {
  font-size: 12px !important;
}

.dropdown-item:active,
.nav-pills .show>.nav-link {
  background-color: #333A3F;
  opacity: 0.6;
}

.cluck > span {
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  top: 2px;
  left: 8px;
}

.dark .blue-border:hover,
.dark .blue-border {
  border: 1px solid #59CBE8;
  border-radius: 6px;
}

.black-chyna {
  background-color: #f5f5f5;
}
.dark .black-chyna {
  background-color: #121212;
}

.blue-border:hover,
.blue-border {
  border: 1px solid #222222;
  border-radius: 8px;
}

.pin-me {
  cursor: pointer;
  left: -5px;
  position: absolute;
  color: gray;
  top: -5px;
}

.pin-me-down-daddy {
  background-color: red;
  border-radius: 10px;
  box-shadow: 1px 1px 1px gray;
  color: white;
  left: -7px;
  height: 20px;
  position: absolute;
  top: 4px;
  width: 20px;
}

.pin-me-down-daddy-2 {
  background-color: red;
  border-radius: 10px;
  box-shadow: 1px 1px 1px gray;
  color: white;
  left: -7px;
  height: 20px;
  position: absolute;
  top: 4px;
  width: 20px;
}

.pin-me-down-daddy > span {
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  top: 1px;
  left: 8px;
}

.pin-me-down-daddy-2 > span {
  font-weight: 700;
  font-size: 12px;
  position: absolute;
  top: 1px;
  left: 5px;
}

.pin-me-down-daddy-3 {
  border-radius: 15px;
  box-shadow: 1px 1px 1px lightgray;
  height: 30px;
  width: 30px;
}

.pin-me-down-daddy-3 > span {
  font-weight: 700;
  font-size: 13px;
  position: absolute;
  top: 6px;
  left: 11px;
}

.border-right {
  border-right: 1px solid lightgray !important;
}

.mobile-padding {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

body {
  position: relative
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.dolla-dolla-bill-yall {
  color: green;
  font-size: 28px;
  margin-right: 4px;
  margin-top: 5px;
  opacity: 0.8;
}

button {
  overflow: hidden;
}

p,
h1,
h2,
h3,
h4,
h5,
h6 {
  margin-bottom: 0px;
}

.any-week {
  border-left: 1px solid black;
  border-right: 1px solid black;
}

.become-a-host-btn {
  border-radius: 30px;
  margin-right: 10px;
  min-width: 115px;
  padding-left: 18px;
  padding-right: 18px;
}

#dropdown-basic::after{
  display: none;
}

.fleeklet {
  background-color: #343a40;
  color: #dee2e6;
  cursor: pointer;
  font-family: 'Dosis';
  font-weight: 200;
  padding: 4px;
}
.fleeklet:hover {
  background-color: #52575C;
  color: white;
  font-weight: 500 !important;
}

button.gm-ui-hover-effect {
  display: none !important;
}
.huckleberry-small {
  font-size: 1.5rem;
}

.block {
  border: 1px solid black;
  border-radius: 10px;
  cursor: pointer;
  margin: 4px 4px 16px 4px;
  padding: 16px 24px;
  min-width: 120px;
}

.block svg {
  font-size: 27px;
  margin: 6px;
}

.bold-block {
  background-color: #d3d3d3;
  border: 2px solid black !important;
  border-radius: 8px;
  cursor: pointer;
}

.dark .bold-block {
  background-color: #333333;
  border: 2px solid #d3d3d3 !important;
  border-radius: 8px;
  cursor: pointer;
}

.bold-block svg {
  font-size: 22px;
}

.bold-pill {
  border-width: 2px !important;
}

.brown-text {
  background-color: rgb(247, 247, 247) !important;
  color: brown;
}

.calendar-modal {
  width: 90vw;
  max-width: 90vw;
}

@media screen and (min-width: 1200px) {
  .calendar-modal {
    width: 1100px !important;
    max-width: 1100px !important;
  }
}

@media screen and (min-width: 576px) and (max-width: 992px) {
  .lee-wook {
    padding-left: 0px;
  }
}

.cursor {
  cursor: pointer;
}

.flexible-stay-length span {
  border: 1px solid black;
  border-radius: 25px;
  cursor: pointer;
  margin: 12px 7px;
  padding: 14px 16px 10px 18px;
}

.fw-bold {
  font-weight: 700;
}

.header-padding {
  padding: .25rem 3rem;
}

.gradient {
  background-image: linear-gradient(to bottom, #050A30 0%, #000072 16%, #000072 50%, black 100%);
  border-right: 1px solid #333333;
  color: #fff;
}

.horizontal-scroll {
  overflow: auto;
}

.extra-small-btn {
  font-size: 10px;
  height: 20px;
  padding-top: 2px;
}

.in-blocked-range {
  background: #ececec !important;
  border-radius: 0px !important;
  border: 1px solid gray !important;
  color: gray !important;
  font-weight: 600;
}

.in-selected-range {
  background: lightgray !important;
  border-radius: 0px !important;
  border: none !important;
}

.listing-image {
  border-radius: 4% !important;
  border: 1px solid black;
  height: 22vh;
  object-fit: cover;
  width: 100%;
}

.margin-auto {
  margin: auto;
}

.me-auto {
  margin-right: auto;
}

.profile {
  border: 1px solid black;
  border-radius: 25px;
  cursor: pointer;
  height: 50px;
}

.profile svg {
  margin-top: 13px;
}

.flag-1 {
  border: 1px solid gray;
  height: 34px;
}

.profile-image {
  border: 1px solid gray;
  border-radius: 17px;
  height: 20px;
  width: 20px;
}

.rounded {
  border-radius: 15px !important;
}

@media  (max-width: 991px) {
  .h-100 {
    height: 91% !important;
  }
  .react-calendar button { font-size: 12px; padding-top: 12px; padding-bottom: 12px; }
  .jahmon { height: 80vh; }
  .fuckopelli {
    height: 100px !important;
    line-height: 100px !important;
  }
  .listing-image { 
    height: 33vw;
  }
  .total-revenue {
    height: 100px !important;
    line-height: 100px !important;
    max-width: 100px;
  }
  .flexley {
    justify-content: start;
  }
  .jahStepHeight {
    height: 70vh;
  }
  .jahStepHeight1 {
    height: 88vh;
  }
  .footer-fatley {
    padding-left: 2.75rem !important;
    padding-right: 2.75rem !important;
  }

  .wookie-life {
    padding-left: .25rem;
    padding-right: .25rem;
  }
}

@media (min-width: 992px) {
  .em-beezley{
    padding-bottom: 1.5rem;
  }
  .p-teezley {
    padding-top: 2.5rem;
  }
  .flexley {
    justify-content: between;
  }
  .pt-ku {
    padding-top: .58rem;
  }
  .jahmon {
    height: 100vh;
  }
  .fuckopelli {
    height: 200px !important;
  }

  .jahVenley {
    height: 40px;
  }

  .bigly-font {
    font-size: 23px;
    padding-bottom: 0;
    padding-top: 0;
    padding-left: 8px;
  }

  
  .jahStepHeight {
    height: 100vh;
  }
  .jahStepHeight1 {
    height: 100vh;
  }
}

.flexible {
  justify-content: end;
}

.search {
  border: 1px solid black;
  border-radius: 25px;
  box-shadow: 2px 2px 5px lightgray;
  cursor: pointer;
  padding: 12px;
  width: 345px;
}

.square-small-image {
  border-radius: 10px;
  height: 80x;
  width: 80px;
}

.title {
  font-weight: 700;
  cursor: pointer;
}

.titleXL {
  font-weight: 700;
  cursor: pointer;
  font-size: 28px;
}

.matty-titties {
  margin-top: 2rem;
}

.pitty-titties {
  padding-left: 3rem;
  padding-right: 3rem;
}

.show-map {
  background-color: #222222;
  border-radius: 25px;
  border: 1px solid white;
  bottom: 4%;
  color: white;
  cursor: pointer;
  font-weight: 700;
  left: 50%;
  padding: 12px 25px;
  position: fixed;
  transform: translate(-50%, -50%);
  z-index: 2;
}

.show-map svg {
  margin-left: 15px;
}

.linear-wipe {
  animation: shine 4s ease-in infinite;
  background: linear-gradient(to right, brown 46%, #C46316 50%, brown 54%);
  background-clip: text;
  background-size: 200% auto;
  -webkit-background-clip: text;
  -webkit-text-fill-color: transparent;
}

.round-gray-box {
  background: rgb(235, 235, 235);
  border-radius: 25px;
  cursor: pointer;
  padding: 6px;
  width: 250px;
}

.round-gray-box h6 {
  font-weight: 500;
  padding: 7px 18px;
}

.round-white-box {
  background-color: white;
  border-radius: 20px;
  box-shadow: 1px 1px 3px lightgray;
}


.slide-left {
  position: absolute;
  padding-left: 62px;
  left: -500px;
  height: 100px;
  -webkit-animation: slide 0.5s forwards;
  animation: slide 0.5s forwards;
  animation-delay: 1s;
}

.slide-left1 {
  position: absolute;
  padding: 40px;
  left: -500px;
  width: 270px;
  height: 100px;
  -webkit-animation: slide1 0.5s forwards, slide1 0.5s forwards, slideout 0.5s forwards;
  animation: slide1 0.5s forwards, slideout 0.5s forwards;
  animation-delay: 3s, 9s;
}

@-webkit-keyframes slide1 {
  100% {
    left: 120px;
  }
}

@keyframes slideout {
  100% {
    left: -400px;
  }
}

@keyframes slide1 {
  100% {
    left: 120px;
  }
}

@-webkit-keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes slide {
  100% {
    left: 0;
  }
}

@keyframes shine {
  to {
    background-position: 200% center;
  }
}

.vh-70 {
  height: 70vh;
}

.vh-100 {
  height: 100vh !important;
}

.light-gray {
  color: gray;
}

.badges>span {
  border: 1px solid gray;
  border-radius: 15px;
  color: black;
  cursor: pointer;
  font-size: 15px;
  font-weight: 400;
  margin-right: 8px;
  opacity: 0.8;
  padding: 11px 17px;
}

.characters {
  background-color: transparent;
  list-style: none;
  padding: 0;
  min-height: 60vh;
}

.characters li {
  display: flex;
  align-items: center;
  padding: .5em .8em .5em .5em;
}

.characters p {
  max-width: none;
  font-weight: bold;
  margin: 0;
}

.characters-thumb {
  width: 100%;
}

.characters-thumb img {
  border-radius: 10px;
  border: 2px solid black;
  display: block;
  width: 100%;
  object-fit: cover;
  height: auto;
  max-height: 300px;
}

.lipbopdick {
  max-height: 85vh !important;
}

.badges>.active-badge {
  color: black;
  border: 2px solid black !important;
  box-shadow: 1px 1px 3px lightgray;
}

.peener {
  padding: 2rem;
}

.no-guests {
  background-color: #fff;
  border: 1px solid #333333;
  border-radius: 11px;
  margin-top: 18px;
}

@media screen and (max-width: 768px) {
  .flex-xs-column-reverse {
    flex-direction: column-reverse !important;
  }
}

.disable-overflow {
  overflow-y: hidden !important;
}

.tweener {
  width: 100%
}

.reorder {
  display: none;
}

.veeh-100 {
  height: 100vh !important;
}

.peener {
  background-color: #121212;
  border-top: 1px solid #333333;
}


@media screen and (min-width: 992px) {
  .car-talk {
    padding-left: .75rem;
  }
  .fixed-left {
    color: lightgray;
    font-size: 51px;
    position: absolute;
    left: 50px; 
    top: 46%;
  }

  .fixed-right {
    color: lightgray;
    font-size: 51px;
    position: absolute;
    right: 50px; 
    top: 46%;
  }

  .piggy { 
    padding-left: 0 !important;
  }

  .shitty { padding-left: 0px; }

  .tweener {
    float: right;
    width: 50%
  }
}

@media screen and (max-width: 991px) {
  .veeley {
    height: 100vh;
  }
  .dem-beezley {
    padding-bottom: 2rem;
  }
  .flexible {
    justify-content: center;
  }

  .p-teezley {
    padding-top: .75rem !important;
  }

  .jahVenley {
    height: 20px;
  }
  .addressy {
    margin-top: 8px;
  }

  .fixed-left {
    color: lightgray;
    font-size: 51px;
    position: absolute;
    left: 10px; 
    bottom: 10vh;
  }

  .fixed-right {
    color: lightgray;
    font-size: 51px;
    position: absolute;
    right: 10px; 
    bottom: 10vh;
  }
  .piggy { 
    padding-left: 16px !important;
  }
  .piggywrapper {
    padding-top: 0.5rem;
  }

  .full-width-on-small {
    width: 100% !important;
  }
  .peener { padding: 1rem; }
  .header-padding {
    padding: 0.25rem .5rem;
  }

  .fuckcss { padding-left: .4rem; padding-right: .4rem; }
  .reorder {
    display: block;
  }

  .characters {
    max-height: unset;
  }

  .characters-thumb img {
    max-height: 120px;
  }

  .dolla-dolla-bill-yall {
    font-size: 20px;
    margin-right: 4px;
    opacity: 0.8;
  }

  .fuck {
    height: 30px;
  }

  .addressy {
    font-size: 19px;
    margin: 0;
    text-align: center;
  }

  .block {
    padding: 8px 24px;
  }

  .cancel-btn {
    margin-right: 3rem !important;
  }

  .lipbopdick {
    height: 58vh !important;
  }

  .fixed-bottom {
    border-top: 1px solid lightgray;
  }

  .circle {
    background-color: #d83b01;
    border-radius: 50%;
    color: black;
    height: 2.5em;
    position: relative;
    width: 2.5em;
  
    border: 1px solid transparent;
  }


.circleText {
  text-align: center;
  height: 50%;
  left: 50%;
  position: absolute;
  top: 50%;
  transform: translate(-50%, -50%);
  width: 50%;
}

.hover {
  background-color: #0078d4;
}

  .square-small-image {
    width: 50px;
  }

  .matty-titties {
    margin-top: 0;
  }

  .pitty-titties {
    padding-left: 1rem;
    padding-right: 1rem;
  }
}

@media screen and (max-width: 580px) {
  .become-a-host-btn {
    display: none;
  }

  .lipbopdick {
    height: 54vh !important;
  }
}

@media screen and (max-width: 525px) {
  .jah-shitley {
    display: none;
  }
}

@media screen and (min-width: 526px) {
  .jah-shitley {
    padding-left: 6px;
  }
}


.flex-fill {
  flex: 1;
}

.show {
  visibility: visible;
  opacity: 1;
}

.grid-container {
  display: grid;
  grid-template-columns: auto auto;
  max-height: 52vh;
  overflow: scroll;
}



.grid-item {
  padding-right: 8px;
}

.grid-item>img {
  border-radius: 6px;
}

.jahs-foot {
  box-shadow: -2px -2px 8px lightgray;
}

.hucklberry-home-photo {
  height: calc(420px + .6rem);
  object-fit: cover;
  width: 100%;
}

.hucklberry-home-photo-half {
  background-color: #ededed;
  border-radius: 3% !important;
  height: 210px;
  object-fit: cover;
  width: 100% !important;
}
.round-image {
  max-height: 90px;
}
.round-image img{
   background-color: lightgray;
   border: 1px solid black;
   border-radius: 40px;
   box-shadow: 2px 3px 5px lightgray;
   height: 80px;
   max-height: 90px;
   position: relative;
   width: 80px;
}

.round-image #verified-check {
  border-radius: 10px;
  box-shadow: 1px 2px 4px gray;
  position: absolute; 
  bottom: 12px;
  right: 2px;
  height: 20px;
  width: 20px;
  background-color: red;
}

.full-page-image {
  background-color: black;
  position: fixed;
  height: 100vh;
  top: 0px;
  bottom: 0px;
  left: 0px;
  right: 0px;
}
.full-page-image img {
  object-fit: contain;
  width: 100%;
  height: 100%;
}

.w-36 {
  width: 36%;
}

.total-revenue {
  position: relative;
  display:inline-block;
  line-height:200px;
  height: 200px;
  text-align: center;
  color: #485564;
  border-radius: 100px;
  overflow: hidden;
  width: 200px;
  z-index:0;
}

.total-revenue:before {
  content: '';
  position: absolute;
  top: 13px;
  right: 13px;
  bottom: 13px;
  left: 13px;
  border-radius: 100px;
  box-sizing: border-box;
  z-index: -1;
}

.whiteify:before {
  background-color: white;
}

.blackify:before {
  background-color: black;
}